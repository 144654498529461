<template>
<div>
<div class="layouts-header-container" style="background-color:transparent;box-shadow: 0 0px 0px rgba(0,0,0,.15);height: 0px;margin: 60px auto;min-width: 1201px;">
    <div class="layouts-header-menu layouts-header-left" style="">
      <router-link to="/" class="layouts-header-logo" style="display: flex; align-items: center;">
        <img style="height: 32px;" :src="logo" />
      </router-link>
      <div v-if="isNotLoggedIn || currentUserInfo.is_able_to_otc">
        <a target="_blank" rel="noopener noreferrer nofollow" :href="otc_url" class="layouts-header-mainItem"  style="color: #f7f6ff;height: 24px; font-weight: 500; color: #FFFFFF; font-size: 1vw;">
          {{$t("currency_trading")}}
        </a>
      </div>
      <div v-if="isLoggedIn && !currentUserInfo.is_able_to_otc">
        <a target="_blank" rel="noopener noreferrer nofollow" @click="dont_alow_otc()" class="layouts-header-mainItem"  style="color: #f7f6ff;">
          {{$t("currency_trading")}}
        </a>
      </div>
      <a target="_blank" class="layouts-header-mainItem ant-dropdown-trigger" :href="trading_url" style="color: #f7f6ff; height: 24px; font-weight: 500; color: #FFFFFF; font-size: 1vw;">
        {{$t("currency_transaction")}}
      </a>
      <!--
      <a target="_blank" class="layouts-header-mainItem ant-dropdown-trigger"  @click="salesPromotionVue" style="color: #f7f6ff">
        {{$t("sales_promotion")}}
      </a>
      -->
      <a target="_blank" class="layouts-header-mainItem ant-dropdown-trigger" :href="gda_chain_url" style="color: #f7f6ff; height: 24px; font-weight: 500; color: #FFFFFF;font-size: 1vw;">
        {{$t("gda_chain")}}
      </a>
      <a target="_blank" class="layouts-header-mainItem ant-dropdown-trigger" :href="gda_blockchain_url" style="color: #f7f6ff; height: 24px; font-weight: 500;color: #FFFFFF; font-size: 1vw;">
        {{$t("gda_blockchain")}}
      </a>
      <a class="layouts-header-mainItem ant-dropdown-trigger" href="javascript:;"style="color: #f7f6ff;">
        <el-dropdown>
          <span>
            {{$t("announcement_center")}}
          </span>
          <el-dropdown-menu slot="dropdown" style="margin-top: 20px">
            <el-dropdown-item  v-for="item in announcements">
              <div class="announcement" @click="go_cms_article_detail(item.id)">
                <span>
                  {{item.title}}
                </span>
                <span>
                  {{item.create_time}}
                </span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <a target="_blank" class="announcement" :href="vue_cms_url">
                <span>
                  {{$t("more")}}
                </span>
                <span>
                </span>
              </a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </a>
      <a target="_blank" class="layouts-header-mainItem ant-dropdown-trigger" :href="decentralized_trading_url" style="color: #f7f6ff; height: 24px; font-weight: 500; color: #FFFFFF; font-size: 1vw;">
        {{$t("decentralized_trading")}}
      </a>
    </div>
    <div class="layouts-header-menu layouts-header-right">
      <div @click="switchTheme()"  class="theme_light"></div>
      <!-- <a class="layouts-header-mainItem ant-dropdown-trigger">
        <el-dropdown>
          <span>
            {{$t("tool")}}
          </span>
          <el-dropdown-menu slot="dropdown" style="margin-top: 20px">
            <el-dropdown-item  @click.native="go_digital_assets()">{{$t("introduction_to_digital_assets")}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </a> -->
      <a class="layouts-header-mainItem ant-dropdown-trigger" v-show="is_login">
        <el-dropdown>
          <span>
            {{$t("assets")}}
          </span>
          <el-dropdown-menu slot="dropdown" style="margin-top: 20px">
            <el-dropdown-item  @click.native="go_assets()">{{$t("my_assets")}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </a>
      <a class="layouts-header-mainItem components-selectlang-index-lang ant-dropdown-trigger"  style="color: #f7f6ff">
      <!--
        <i class="iconfont icon-diqiu" style="font-size: 18px;margin-bottom: 3px"></i>
        -->
        <img style="height: 36px; width: 36px;" src= "https://cdn.gda.top/files/other/17301665852d5.png">
        <el-dropdown style="margin-left: 3px; font-weight:500; font-size: 1vw; color: #ffff;">
          <span v-html="language_name">
            English
          </span>
          <el-dropdown-menu slot="dropdown" style="margin-top: 20px">
            <el-dropdown-item v-for="item in languageData">
              <div class='lang' @click="changeLanguage(item.label)">
                <span :class="[item.label == lang ? 'selected' : null ]" >
                </span>
                <span>{{item.name}}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </a>
      <div @click="go_message()" class="message" style="cursor:pointer;">
        <img style="height: 36px; width: 36px; margin-bottom: 4px;" src= "https://cdn.gda.top/files/other/173016659424c.png">
        <div style="color: #E02020; font-size: 1vw; margin-top: -53px; margin-left: 2px;" v-if="isLoggedIn && currentHaveNewMessage">·</div>
      </div>
      <a class="layouts-header-mainItem ant-dropdown-trigger" style="position: relative;color: #f7f6ff" @mouseenter="showUserCenter(isHideUserCenter)" @mouseleave="hideUserCenter(isHideUserCenter)"  v-if="is_login">
        <i class="iconfont icon-geren" style="font-size: 1vw;"></i>
        <div style="position: absolute;top: 15px; left: -130px; width: 100%;">
          <div>
              <div class="ant-dropdown  ant-dropdown-placement-bottomCenter  " :class="[isHideUserCenter ? 'ant-dropdown-hidden' : '' ]"
                  style="left: -5px; top: 0px;">
                  <ul class="ant-dropdown-menu layouts-header-userMenu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical"
                      role="menu" tabindex="0" style="background: #ffffff">
                      <li class="ant-dropdown-menu-item layouts-header-noHover" role="menuitem" style="color: #6c7197">
                          <span>{{username}}</span>
                      </li>
                      <li class=" ant-dropdown-menu-item-divider" style="background-color: rgb(211,213,225)"></li>
                      <li class="ant-dropdown-menu-item layouts-header-noHover" role="menuitem" style="line-height: 4px;margin-top: 18px;">
                          <div class="layouts-header-userPower" style="color: #6c7197;border-bottom:0px;justify-content: flex-start;margin-left: 12px;">
                            <p class="layouts-header-none" v-if="auth_status == 1" style="color: var(--features-make-color)">
                              <i class="iconfont icon-idcard" style="vertical-align: -1px;"></i> &nbsp;{{$t("verified")}}
                            </p>
                            <p class="layouts-header-none" @click="go_auth" v-if="auth_status == 2" style="color: #a5a5ba">
                              <i class="iconfont icon-idcard" style="vertical-align: -1px;"></i> &nbsp;{{$t("not_certified")}}
                            </p>
                            <p class="layouts-header-none" v-if="auth_status == 3" style="color: #a5a5ba">
                              <i class="iconfont icon-idcard" style="vertical-align: -1px;"></i> &nbsp;{{$t("under_review")}}
                            </p>
                            <p class="layouts-header-none" @click="go_auth" v-if="auth_status == 4" style="color: #ef6161">
                              <i class="iconfont icon-idcard" style="vertical-align: -1px;"></i> &nbsp;{{$t("authentication_failed")}}
                            </p>
                          </div>
                      </li>
                      <li class="ant-dropdown-menu-item hover_bg" role="menuitem" @click="clickCenter">
                        <router-link to="/user_center" style="color: #6c7197">{{$t("personal_center")}}</router-link>
                      </li>
                      <!-- <li class=" ant-dropdown-menu-item-divider" style="background-color: rgb(211,213,225)"></li> -->
                      <li class="ant-dropdown-menu-item hover_bg" role="menuitem"  @click="UserProtocol"  style="color: #6c7197" v-if="currentProtocolForUsers">
                        <span style="margin-left: 15px">
                          {{$t('subscription_agreement')}}
                        </span>
                      </li>
                      <li class=" ant-dropdown-menu-item-divider" style="background-color: rgb(211,213,225)"></li>
                      <li class="ant-dropdown-menu-item hover_bg" role="menuitem"  @click="signOut"  style="color: #6c7197">
                        <span>
                          <i class="iconfont icon-tuichu1" style="font-size: 1vw; margin-right: 5px;"></i>
                          <span>
                            {{$t("exit_account")}}
                          </span>
                        </span>
                      </li>
                  </ul>
              </div>
            </div>
         </div>{{username}}
      </a>
      <a :href="loginUrl" v-if="!is_login">
        <button type="button" class="ant-btn ant-btn-primary" style="margin-right: 10px; background-color: #ffff; border-color: transparent; border-radius: 8px; color: #25BDF3; white-space: normal; line-height: 1.2; word-break: break-word; min-width: 100px; font-size: 12px;">
          <span>
            {{$t("login")}}
          </span>
        </button>
      </a>

      <a :href="signUrl" v-if="!is_login">
        <button type="button" class="ant-btn ant-btn-primary" style="margin-right: 15px; background-color: transparent; border-radius: 8px; border: 1px solid #fff; white-space: normal; line-height: 1.2; word-break: break-word; min-width: 110px; font-size: 12px;">
          <span>
            {{$t("registered")}}
          </span>
        </button>
      </a>
    </div>
</div>
<user-protocol ></user-protocol>
</div>
</template>
<style type="text/css" scoped>
  .hover_bg:hover{
    background-color: #e4f2e9;
  }
  .theme_light{
    min-width:30px;
    min-height:30px;
    margin-right: 16px;
    background-image: var(--home-header-light-bgimg);
    background-color: rgba(0, 0, 0, 0);
  }
  /*.backg-img{
    background-image: var(--message_bell);
    width: 16px;
    height: 16px;
  }*/
  .message{
    width:36px;
    height:36px;
    margin-right: 16px;
    margin-left: 16px;
    background-color: rgba(0, 0, 0, 0);
  }
  .layouts-header-mainItem.ant-dropdown-trigger .el-dropdown[data-v-92622a1e] {
    font-size: 1vw !important;
  }
</style>
<script>
import UserProtocol from './UserProtocol'
import { white_logo } from '../application'
import Vue from 'vue'
export default {
  name: 'HomeNavHeader',
  data () {
    return {
      otc_url: '',
      trading_url: '',
      vue_cms_url: '',
      gda_chain_url: '',
      gda_blockchain_url: '',
      decentralized_trading_url: '',
      loginUrl: '',
      signUrl: '',
      logo: white_logo,
      isHideUserCenter:true,
      announcements: [],
      languageData:[
          {
            label:'en',
            name:'English'
          },
          {
            label:'ru',
            name:'русский'
          },
          {
            label:'jp',
            name:'日本語'
          },
          {
            label:'kr',
            name:'한국어'
          },
          {
            label:'zh',
            name:'繁体中文'
          }
      ]
    }
  },
  components: {
    UserProtocol
  },
  mounted() {
      this.loginUrl = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
      this.signUrl = Vue.getValueByKey('cas_path')+'/sign_up?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
      this.otc_url = Vue.getValueByKey('otc_path') + '?lang=' + this.currentLang;
      this.trading_url = Vue.getValueByKey('trading_path') + '?lang=' + this.currentLang;
      this.gda_chain_url= Vue.getValueByKey('gda_chain_path');
      this.gda_blockchain_url= Vue.getValueByKey('gda_blockchain_path');
      this.decentralized_trading_url = Vue.getValueByKey('decentralized_trading_path');
      this.vue_cms_url = Vue.getValueByKey('vue_cms_path') + '?lang=' + this.currentLang;
      this.getUrl('/help_center/recent_announcements', {
          lang:this.currentLang
      }).then((response) => {
          if(response.data.code == 20000){
            this.announcements = response.data.dataResult;
          }
      });
  },
  computed: {
    auth_status: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.auth_status
        }else{
            return false;
        }
    },
    is_login: function () {
      if(this.isLoggedIn){
        return true;
      }else{
        return false;
      }
    },
    username: function () {
      if(this.isLoggedIn){
        return this.currentUserInfo.nick_name
      }else{
        return '';
      }
    },
    lang: function () {
      return this.currentLang;
    },
    language_name: function(){
      if(this.currentLang == 'en'){
          return "English"
      }else if(this.currentLang == 'ru'){
          return "русский"
      }else if(this.currentLang == 'jp'){
          return "日本語"
      }else if(this.currentLang == 'kr'){
          return "한국어"
      }else if(this.currentLang == 'zh'){
          return "繁体中文"
      }else{
          return "English";
      }
    }
  },
  methods: {
    dont_alow_otc(){
      this.$message({
        message: this.$t('is_not_allowed'),
        type: 'error'
      })
      return
    },
    salesPromotionVue(){
      this.$router.push({ path: ('/sales_promotion')});
    },
    clickCenter(){
      this.isHideUserCenter = true;
    },
    go_cms_article_detail(id){
      window.open(Vue.getValueByKey('vue_cms_path') + '/home_article_detail/' + id+ '?lang=' + this.currentLang,"_blank");
    },
    go_digital_assets(){
      this.$router.push({path:'/asset_introduction'}).catch(data => {  })
    },
    go_message(){
      if (!this.currentJudgeIsLoggedIn) {
        location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
      }
      this.$router.push({path:'/message'}).catch(data => {  })
    },
    go_assets(){
      this.$router.push({path:'/my_assets'}).catch(data => {  })
    },
    go_auth(){
      this.$router.push({path:'/identity_auth'}).catch(data => {  })
    },
    showUserCenter (){
      this.isHideUserCenter = false;
    },
    hideUserCenter (){
      this.isHideUserCenter = true;
    },
    changeLanguage (language) {
      Vue.setStorageVariable({'lang':language});
      location.reload();
    },
    switchTheme () {
      Vue.setStorageVariable({'theme': this.currentTheme === 'dark' ? 'light' : 'dark'})
    }
  }
}
</script>
<style lang="scss" scoped>
/* dropdown组件导致的颜色不同修复 */
.layouts-header-mainItem.ant-dropdown-trigger .el-dropdown {
  outline: none;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  transition: color .3s;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}
.layouts-header-mainItem.ant-dropdown-trigger .el-dropdown:hover {
  color: var(--active-color);
}
.announcement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    span:first-child {
      text-align: left;
      width: 250px;
      overflow: hidden;
      /*文本不会换行*/
      white-space: nowrap;
      /*当文本溢出包含元素时，以省略号表示超出的文本*/
      text-overflow: ellipsis;
    }
    span:last-child {
      text-align: right;
      width: 50px;
    }
}
.lang {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    span:first-child {
      position: relative;
      width: 15px
    }
    span.selected::after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--active-color);
    }
    span:last-child {
      width: 85px
    }
}

@media (max-width: 768px) {
  .layouts-header-container {
    min-width: auto;
    padding: 0 20px;
    margin: 20px auto;
  }
  .layouts-header-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .layouts-header-mainItem {
    font-size: 10px; /* 缩小字体大小 */
    margin-bottom: 10px; /* 增加元素间距 */
  }
  .layouts-header-logo img {
    height: 24px; /* 缩小 logo 高度 */
  }
  .layouts-header-mainItem.ant-dropdown-trigger .el-dropdown[data-v-92622a1e] {
    font-size: 10px !important;
  }
}
</style>
