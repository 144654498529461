module.exports = {
  cas_url: 'https://cas.gda.top',
  api_url: 'https://api.gda.top/api/v2',
  websocket_ws: "wss://websocket.gda.top/ws/",
  www_url: 'https://www.gda.top',
  otc_url: 'https://otc.gda.top',
  trading_url: 'https://trading.gda.top',
  vue_cms_url: 'https://cms.gda.top',
  admin_cms_url: 'https://cmsgly.gda.top',
  //cdn_url: 'https://showmethemoney.fuxinsci.com',
  cdn_url: 'https://cdn.gda.top',
  gda_chain_url: 'https://gdachain.com/',
  gda_blockchain_url: 'http://gdascan.io/',
  decentralized_trading_url: 'https://bnb.gdadex.com/',
}
